import React, { useEffect, useState, useCallback } from 'react'
import ImageContainer from '../../../../components/image/image-container'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import SwiperProducts from './components/swiper-products'
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
import withGroup from './hoc/withGroup'
import { TabSwiperCus } from './components/swiper-tabs'
import { FormattedMessage } from 'react-intl'
import { getStyleObjectFromString } from '../../../../utils/style'
import withObserver from '../hoc/withObserver'
import { useApp, useQuery } from '../../../../hooks/common'
import ViewMore from './components/view-more'

const CollectionImage = props => {
    const { collection, sensors, isApp } = props
    const aspectRatio = collection?.width && collection?.height ? `${collection?.width} / ${collection?.height}` : undefined
    return <ImageContainer style={{ aspectRatio }} href={isApp ? collection?.appHref : collection?.href} sensors={sensors}>
        <LazyLoadImage alt={collection?.title} src={collection?.src} />
    </ImageContainer>

}

export default withGroup(withObserver(props => {
    useStyles(classes)
    const { collections, data, sensors, innerRef } = props
    const [collection, setCollection] = useState(collections?.[0])
    const [position, setPosition] = useState(1)
    const styles = getStyleObjectFromString(data?.style)
    const isApp = useApp()
    const query = useQuery()
    const id = data?.id
    const selectedIndex = query?.locationModule === id ? Number(query?.selectedIndex) : 0
    const dark = data?.dark

    useEffect(() => {
        setCollection(collections?.[0])
    }, [collections])

    return <div ref={innerRef} id={data?.id} className={`${classes.ListContainerF} ${collections?.length > 1?'':classes.Single}`} style={{  paddingBottom: 10, paddingTop: `${data?.marginTop}`, ...styles }}>
        {
            data?.styledTitle && <div className={classes.Title}>
                <span dangerouslySetInnerHTML={{ __html: data.styledTitle }} />
            </div>
        }
        <div className={classes.Container} style={{
            backgroundImage: `url(${data?.theme?.backgroundUrl})`,
        }}>
            <div className={classes.Hd}>
                <CollectionImage collection={collection} sensors={{
                    ...sensors,
                    title: collection?.title,
                    refId: collection?.refId || collection?.id,
                    position: `${sensors?.position}-${position}`,
                }} isApp={isApp} />
            </div>
            <div className={classes.Bd}>
                {
                    collections?.length > 1 && <div className={classes.SwiperContinaer}>
                        <TabSwiperCus
                            selectedIndex={selectedIndex}
                            theme={data?.theme}
                            sensors={
                                {
                                    ...sensors,
                                    type: '1',
                                }
                            }
                            items={collections} onTab={
                                (item, index) => {
                                    setCollection(item)
                                    setPosition(index + 1)
                                }
                            } />
                    </div>
                }
                <div className={classes.ProductContainer}>
                    <SwiperProducts festival sensors={
                        {
                            resourcepage_title: sensors?.title,
                            resource_position: `${sensors?.position}-${position}`,
                            resource_content: collection?.refId || collection?.id,
                            resource_type: '1',
                        }
                    } slidesPerView={2.2} column={collection?.refId || collection?.id} products={collection?.products} dark={dark}/>
                </div>
            </div>
        </div>



    </div>
}))