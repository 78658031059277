import React, { useEffect, useState, useCallback } from 'react'
import ImageContainer from '../../../../components/image/image-container'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import ListProducts from './components/list-products'
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
import withGroup from './hoc/withGroup'
import { TabSwiper3 } from './components/swiper-tabs'
import { FormattedMessage } from 'react-intl'
import { getStyleObjectFromString } from '../../../../utils/style'
import withObserver from '../hoc/withObserver'
import { useApp, useQuery } from '../../../../hooks/common'
import ViewMore from './components/view-more'

export default withGroup(withObserver(props => {
    useStyles(classes)
    const { collections, data, sensors, innerRef } = props
    const [collection, setCollection] = useState(collections?.[0])
    const [position, setPosition] = useState(1)
    const styles = getStyleObjectFromString(data?.style)
    const aspectRatio = data?.width && data?.height ? `${data?.width} / ${data?.height}` : undefined
    const isApp = useApp()
    const query = useQuery()
    const id = data?.id
    const selectedIndex = query?.locationModule === id ? Number(query?.selectedIndex) : 0

    useEffect(() => {
        setCollection(collections?.[0])
    }, [collections])

    return <div ref={innerRef} id={data?.id} className={classes.ListContainer} style={{ paddingBottom: 10, paddingTop: `${data?.marginTop}`, ...styles }}>

        {
            data?.styledTitle && <div className={classes.Title}>
                <span dangerouslySetInnerHTML={{ __html: data.styledTitle }} />
            </div>
        }

        {
            data?.src && <ImageContainer style={{ aspectRatio }} href={isApp ? data?.appHref : data?.href} sensors={{
                ...sensors,
                refId: data?.refId,
                type: '1',
            }}>
                <LazyLoadImage alt={data?.title} src={data?.src} />
            </ImageContainer>
        }

        {
            collections?.length > 1 && <div>
                <TabSwiper3
                    selectedIndex={selectedIndex}
                    theme={data?.theme}
                    sensors={
                        {
                            ...sensors,
                            type: '1',
                        }
                    }
                    items={collections} onTab={
                        (item, index) => {
                            setCollection(item)
                            setPosition(index + 1)
                        }
                    } />
            </div>
        }



        <div className={classes.FestivalWrapper}>
            <div className={`${classes.ListProducts} ${classes.ListFestival}`}>

                <ListProducts festival sensors={
                    {
                        resourcepage_title: sensors?.title,
                        resource_position: `${sensors?.position}-${position}`,
                        resource_content: collection?.refId || collection?.id,
                        resource_type: '1',
                    }
                } column={collection?.refId || collection?.id} products={collection?.products} productCount={data?.productCount || 6}/>

                <div className={`${classes.ViewAll} ${classes.Dark}`}>
                    <ViewMore sensors={
                        {
                            resourcepage_title: sensors?.title,
                            resource_position: `${sensors?.position}-${position}`,
                            resource_content: collection?.refId || collection?.id,
                            resource_type: '1',
                        }
                    } href={isApp ? collection?.appHref : collection?.href}>
                        <FormattedMessage id="view_all" defaultMessage="View All" />{' >'}
                    </ViewMore>
                </div>
            </div>
        </div>


    </div>
}))