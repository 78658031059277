import React from 'react'
import withList from './hoc/withList'
import ImageContainer from '../../../../components/image/image-container'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import SwiperProducts from './components/swiper-products'
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
import { getStyleObjectFromString } from '../../../../utils/style'
import withObserver from '../hoc/withObserver'
import { FormattedMessage } from 'react-intl'
import { useApp } from '../../../../hooks/common'
import ViewMore from './components/view-more'

export default withList(withObserver(props => {
    useStyles(classes)
    const { products, data, sensors, innerRef, top, buyButton } = props
    const isApp = useApp()

    const styles = getStyleObjectFromString(data?.style)

    const url = isApp ? data?.appHref : data?.href

    const aspectRatio = data?.width && data?.height ? `${data?.width} / ${data?.height}` : undefined

    const dark = data?.dark

    return <div ref={innerRef} id={data?.id} className={classes.ListContainer} style={{ paddingTop: `${data?.marginTop}`, ...styles }}>

        {
            data?.styledTitle && <div className={classes.Title}>
                <a href={url} dangerouslySetInnerHTML={{ __html: data.styledTitle }} />
            </div>
        }

        {
            data?.src && <ImageContainer style={{ aspectRatio }} href={url} sensors={{
                ...sensors,
                refId: data?.refId,
                type: '1',
            }} position={0}>
                <LazyLoadImage alt={data?.title} src={data?.src} />
            </ImageContainer>
        }


        <div className={classes.SwiperProducts}>
            <SwiperProducts dark={dark} sensors={
                {
                    resourcepage_title: sensors?.title,
                    resource_position: sensors?.position,
                    resource_content: data?.refId,
                    resource_type: '1',
                }
            } column={data?.refId} products={products} top={top} buyButton={buyButton} />
        </div>
        {
            !!!data?.hideMore && <div className={classes.ViewAll}>

                <ViewMore sensors={
                    {
                        resourcepage_title: sensors?.title,
                        resource_position: sensors?.position,
                        resource_content: data?.refId,
                        resource_type: '1',
                    }
                } href={url}><FormattedMessage id="view_all" defaultMessage="View All" />{' >'}</ViewMore>
            </div>
        }

    </div>
}))