import React from 'react'
import withList from './hoc/withList'
import ImageContainer from '../../../../components/image/image-container'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import SwiperProducts from './components/swiper-products'
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
import { getStyleObjectFromString } from '../../../../utils/style'
import withObserver from '../hoc/withObserver'
import { FormattedMessage } from 'react-intl'
import { useApp } from '../../../../hooks/common'
import ViewMore from './components/view-more'

export default withList(withObserver(props => {
    useStyles(classes)
    const { products, data, sensors, innerRef, top, buyButton } = props
    const isApp = useApp()

    const styles = getStyleObjectFromString(data?.style)

    const url = isApp ? data?.appHref : data?.href

    const aspectRatio = data?.width && data?.height ? `${data?.width} / ${data?.height}` : undefined

    const dark = !!styles?.backgroundImage || !!styles?.backgroundColor

    return <div ref={innerRef} id={data?.id} className={classes.ListContainerF} style={{ paddingBottom: 10, paddingTop: `${data?.marginTop}`, ...styles }}>
        {
            data?.styledTitle && <div className={classes.Title}>
                <span dangerouslySetInnerHTML={{ __html: data.styledTitle }} />
            </div>
        }
        <div className={classes.Container} style={{
            backgroundImage: `url(${data?.theme?.backgroundUrl})`,
        }}>
            <div className={classes.Hd}>
                <ImageContainer style={{ aspectRatio }} href={url} sensors={{
                    ...sensors,
                    refId: data?.refId,
                    type: '1',
                }} position={0}>
                    <LazyLoadImage alt={data?.title} src={data?.src} />
                </ImageContainer>
            </div>
            <div className={classes.Bd}>
                <div className={classes.ProductContainer}>
                    <SwiperProducts outline={dark} isTypePreOrder slidesPerView={2.2} sensors={
                        {
                            resourcepage_title: sensors?.title,
                            resource_position: sensors?.position,
                            resource_content: data?.refId,
                            resource_type: '1',
                        }
                    } column={data?.refId} products={products} top={top} buyButton={buyButton} />
                </div>
            </div>
        </div>



    </div>
}))