import React from 'react'
import Moudles from '../modules'
import { action_fetch_home, setCurrentPage, action_fetch_ab } from '../../../../store/actions/pages/home'
import { action_fetch_menu, setWebsite, action_fetch_websites, setWebsiteInfo } from '../../../../store/actions/global'
import { useSelector, useDispatch, useStore } from 'react-redux'
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
import Affix from '../../../components/affix'
import Cookies from 'js-cookie'
import Footer from '../../../layout/fragments/footer'
import BottomNavigation from '../../../layout/fragments/bottom-navigation'
import IndexAlert from '../../components/index-alert'
// import IndexFixedIcon from '../../components/index-fixicon'
// import IndexLottery from '../../../pages/components/index-lottery/index'
// import BottomSubscribe from '../../../layout/fragments/bottom-subscribe'
import { useBot, useQuery } from '../../../hooks/common'
import { useEffect } from 'react'
// import Nofication from '../../../layout/fragments/notification'
import { SearchInput } from '../../../layout/fragments/header'
// import GiftPickMask from '../../../layout/fragments/gift-pick-mask/giftPickMask'
import { getLuckStarShow } from '../../../../api'
import { useState } from 'react'
// import LuckStar from '../../../layout/fragments/luck-star'
import BlackCouponAlert from '../../../layout/fragments/black-coupon-alert'
import HomeNavSwiper from './fragments/nav-swiper'


const HomePage = props => {

    useStyles(classes)
    const store = useStore()
    const dispatch = useDispatch()
    const pageHome = useSelector((state) => state.pageHome)
    const pageGlobal = useSelector((state) => state.global)
    const ui = useSelector((state) => state.ui)

    const { websites, website } = pageGlobal

    const isBot = useBot()

    const query = useQuery()

    // const [showGiftPick, setShowGiftPick] = useState(false)
    // const [showAlert, setShowAlert] = useState(false);


    useEffect(() => {
        if (window.__INITIAL_STATE__) {
            delete window.__INITIAL_STATE__
        } else {
            if (!pageHome?.pages || pageHome?.pages.length < 1 || pageHome?.currentPage !== website) {
                HomePage.fetchData(store, null, { website: website || '1' }, null)
            }
            window.currentPage = 'home'
            document.title = `Women\'s Fashion Online Shopping`
            if (window.GeekoSensors) {
                window.GeekoSensors.Quick('autoTrackSinglePage', {
                    page_sort: 'home',
                    page_title: '首页',
                    page_content: 'home'
                })
            }
        }
        if (!websites || websites?.length < 1) {
            dispatch(action_fetch_websites({ website: Cookies.get('website') }))
        }
        // getGiftMaskShow()
    }, [])


    const currentPageData = pageHome?.pages?.find(p => p.id === (pageHome?.currentPage || '1'))
    const topWebsites = websites || pageHome?.pages


    // const getGiftMaskShow = async() => {
    //     const res = await getLuckStarShow()
    //     if(res?.code == 200 && res?.result){
    //         setShowGiftPick(true)
    //     } else {
    //         setShowAlert(true);
    //     }
    // }

    // const giftMaskHide = () => {
    //     setShowGiftPick(false)
    // }

    const theme = currentPageData?.page?.theme

    return <div>

        <div className={classes.Search}>
            <SearchInput radius />
        </div>

        {
            topWebsites?.length > 1 ? <Affix offsetTop={44}>
                <HomeNavSwiper
                    topWebsites={topWebsites}
                />
            </Affix> : <div style={{ borderTop: '1px solid #eaeaea' }} />
        }

        <div style={{
            backgroundColor: theme?.backgroundColor || undefined,
            backgroundImage: theme?.backgroundImage ? `url(${theme?.backgroundImage})`: undefined,
        }}>
            {
                currentPageData?.page?.modules?.length > 0 ? <Moudles key={pageHome?.currentPage} abTest={pageHome.abTest} data={currentPageData?.page?.modules} sensors={
                    {
                        title: `home-${currentPageData?.refId}`,
                    }
                } /> : <LoadingHome />
            }
        </div>



        <Footer />

        <BottomNavigation />

        {
            !isBot && <React.Fragment>
                {/* <LuckStar /> */}
                {/* {
                    showGiftPick &&  <React.Fragment>
                        <GiftPickMask onClose={() => giftMaskHide()}/>
                    </React.Fragment>
                } */}

                <BlackCouponAlert />

                {/* <IndexAlert /> */}
                {/* <IndexLottery /> */}
                {/* <IndexFixedIcon /> */}
                {/* <BottomSubscribe/> */}

            </React.Fragment>
        }

        {/* <Nofication/> */}


    </div>
}


const LoadingHome = props => {
    useStyles(classes)
    return <div className={classes.LoadingHome}>
        <div className={classes.Block1}></div>
        <div className={classes.Block2}></div>
        <div className={classes.Block2}></div>
        <div className={classes.Block2}></div>
    </div>
}

HomePage.fetchData = async (store, params, search, headers) => {
    const website = search?.website
    const promises = [
        store.dispatch(action_fetch_home(website, search?.previewCode, headers)),
        // store.dispatch(action_fetch_ab(headers))
    ]
    const data = await Promise.all(promises)
    return data
}

export default HomePage